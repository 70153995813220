import React from "react"

import Hero from "components/molecules/Hero"
import HowItWorks from "components/molecules/HowItWorks"
import PageWrapper from "components/molecules/PageWrapper"
import ReviewSection from "components/molecules/ReviewSection"
import GetStartedSection from "components/organisms/GetStartedSection"
import InlineCalculatorSection from "components/organisms/InlineCalculatorSection"

import BorrowCalculator from "components/molecules/BorrowCalculator"

const Home = () => {
  return (
    <PageWrapper title="Expert mortgage advice" titlePosition="end">
      <Hero />
      <ReviewSection isDark={true} />
      <HowItWorks />
      <GetStartedSection isDark={false} />
      <InlineCalculatorSection>
        <BorrowCalculator orientation="horizontal" />
      </InlineCalculatorSection>
    </PageWrapper>
  )
}

export default Home
