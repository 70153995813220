import React from "react"
import styled, { css, useTheme } from "styled-components"

import { rems } from "base_css/utils/fontSize"
import House from "components/svg/House"
import IconCard from "components/svg/IconCard"

const SM_SCALE = 0.4
const SUB_LOGO_SIZE = 135
const LOGO_HEIGHT = SUB_LOGO_SIZE * 1.5
const LOGO_WIDTH = SUB_LOGO_SIZE * 1.9

interface ILogoStyle {
  size?: "small" | "large"
}

interface ISubLogoStyle extends ILogoStyle {
  type?: "house"
  invert?: boolean
}

const SLogo = styled.div<ILogoStyle>`
  height: ${({ size }) =>
    size === "small" ? rems(LOGO_HEIGHT * SM_SCALE) : rems(LOGO_HEIGHT)};
  min-width: ${({ size }) =>
    size === "small" ? rems(LOGO_WIDTH * SM_SCALE) : rems(LOGO_WIDTH)};
  position: relative;
`

const SSubLogo = styled.div<ISubLogoStyle>`
  align-items: center;
  border-radius: ${rems(6)};
  box-shadow: 0 ${rems(5)} ${rems(20)} 0 rgba(34, 34, 34, 0.15);
  display: flex;
  height: ${({ size }) =>
    size === "small" ? rems(SUB_LOGO_SIZE * SM_SCALE) : rems(SUB_LOGO_SIZE)};
  justify-content: center;
  position: absolute;
  width: ${({ size }) =>
    size === "small" ? rems(SUB_LOGO_SIZE * SM_SCALE) : rems(SUB_LOGO_SIZE)};
  /* stylelint-disable */
  ${({ theme, type, invert }) =>
    type === "house"
      ? css`
          background-color: ${invert ? theme.color.white : theme.color.accent};
          left: 0;
          top: 0;
        `
      : css`
          bottom: 0;
          right: 0;
        `}/* stylelint-enable */
`

interface IMortgageLogo extends Omit<ISubLogoStyle, "type"> {
  className?: string
  testId?: string
}

const MortgageLogo = ({
  className,
  testId,
  size = "large",
  invert,
}: IMortgageLogo) => {
  const theme = useTheme()
  return (
    <SLogo className={className} data-testid={testId} size={size}>
      <SSubLogo invert={invert} size={size}>
        <IconCard />
      </SSubLogo>
      <SSubLogo type="house" invert={invert} size={size}>
        <House
          fill={theme.color.primary}
          size={size === "small" ? 30 : undefined}
        />
      </SSubLogo>
    </SLogo>
  )
}

export default MortgageLogo
