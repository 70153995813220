import Image from "next/image"
import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"

import { rems } from "base_css/utils/fontSize"
import MortgageLogo from "components/atoms/MortgageLogo"

interface ICompanyLogo {
  companyName: string
  logoUrl: string
  testId?: string
}

interface ICompanyLogoStyle {
  width: number
  height: number
}

const SCompanyLogo = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`

const SCompanyLogoCard = styled.div`
  align-items: center;
  border-radius: ${rems(7)};
  box-shadow: 0 ${rems(5)} ${rems(20)} 0 rgba(34, 34, 34, 0.15);
  display: flex;
  height: ${rems(240)};
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.mediumLarge};
  width: ${rems(340)};
`

const SCompanyImageWrapper = styled.div<ICompanyLogoStyle>`
  height: unset;
  position: relative;
  width: 100%;

  /* overwrites next/image's outter styling */
  & > span:first-child {
    position: relative !important;

    img {
      max-height: ${({ height, theme }) =>
        `calc(${height}px - ${theme.spacing.mediumLarge})`} !important;
      max-width: ${({ width, theme }) =>
        `calc(${width}px - ${theme.spacing.mediumLarge})`} !important;
    }
  }
`

const SCompanyImage = styled(Image)`
  height: unset !important;
  position: relative !important;
`

const SMortgageLogo = styled(MortgageLogo)`
  margin-top: -${rems(30)};
`

const CompanyLogo = ({ companyName, logoUrl, testId }: ICompanyLogo) => {
  const [sizing, setSizing] = useState({ width: 0, height: 0 })
  const cardRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (cardRef.current) {
      const { width, height } = cardRef.current.getBoundingClientRect()
      setSizing({ width, height })
    }
  }, [cardRef.current])

  return (
    <SCompanyLogo data-testid={testId}>
      <SCompanyLogoCard ref={cardRef}>
        <SCompanyImageWrapper height={sizing.height} width={sizing.width}>
          <SCompanyImage
            alt={`${companyName}'s logo`}
            src={logoUrl}
            layout="fill"
            objectFit="contain"
            sizes="50vw"
          />
        </SCompanyImageWrapper>
      </SCompanyLogoCard>
      <SMortgageLogo size={"small"} />
    </SCompanyLogo>
  )
}

export default CompanyLogo
