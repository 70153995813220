import React from "react"
import styled from "styled-components"

import { rems } from "base_css/utils/fontSize"

interface IHouseStyle {
  fill?: string
  size?: number
}

const SHouse = styled.svg<IHouseStyle>`
  fill: ${({ fill, theme }) => (fill ? fill : theme.color.white)};
  width: ${({ size }) => (size ? rems(size) : rems(70))};
`

interface IHouse extends IHouseStyle {
  className?: string
}

const House = ({ className, fill = "white", size }: IHouse) => (
  <SHouse
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    data-creative-commons-credit="Author: Saeful Muslim"
    className={className}
    fill={fill}
    size={size}
  >
    <defs id="defs8" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m 51.765501,8.2361987 13.71291,11.4614023 v -2.763168 c 0,-0.340774 0.101932,-0.647571 0.306796,-0.92039 0.204865,-0.204864 0.511661,-0.307796 0.92089,-0.307796 h 15.759054 c 0.273319,0 0.511661,0.102932 0.716525,0.307796 0.204864,0.272819 0.306796,0.579616 0.306796,0.92039 v 17.396468 l 13.20125,10.540014 c 0.204364,0.06895 0.340774,0.204864 0.409229,0.409728 v 0.716525 c -0.06846,0.204864 -0.204865,0.340774 -0.409229,0.408729 -0.204864,0.204864 -0.443706,0.307796 -0.716525,0.307796 h -7.981709 v 26.606364 c 0,4.297151 -0.750503,7.70889 -2.251508,10.233216 C 83.147698,87.64656 78.610706,89.795136 72.130002,90 H 29.968949 C 23.283381,90 18.576001,87.850425 15.84731,83.553273 14.27785,81.028947 13.49337,77.617208 13.49337,73.320057 V 46.713693 H 6.0233217 c -0.2048642,0 -0.4092287,-0.102932 -0.614093,-0.307796 C 5.2043645,46.337942 5.1024321,46.202032 5.1024321,45.997168 5.0339775,45.792304 5,45.553462 5,45.280643 5.067955,45.075779 5.170387,44.939869 5.3067966,44.870915 L 50.33295,8.2361987 c 0.204864,-0.067955 0.443706,-0.1019325 0.716525,-0.1019325 0.204365,0 0.443207,0.033977 0.716026,0.1019325 z"
      id="path2"
    />
  </SHouse>
)

export default House
