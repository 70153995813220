import React, { useEffect, useState } from "react"
import styled from "styled-components"

import BulletList from "components/atoms/BulletList"
import Modal from "components/atoms/Modal"
import InputPounds from "components/molecules/InputPounds"
import Calculator, {
  CalculatorOrientation,
  SCalcInputWrapper,
} from "components/templates/Calculator"
import { intToPounds, poundsToInt, useInputReducer, useModal } from "utils"

const SP = styled.p`
  margin: 0;
`

interface IBorrowCalculator {
  orientation?: CalculatorOrientation
}

const MAX_INCOME = 1000000

const BorrowCalculator = ({ orientation }: IBorrowCalculator) => {
  const { isVisible, toggleModal } = useModal()
  const [firstIncome, firstIncomeDispatch] = useInputReducer<string>("£55,000")
  const [secondIncome, secondIncomeDispatch] = useInputReducer<string>("")
  const [answer, setAnswer] = useState<number>(0)

  const calculate = (firstIncome: string, secondIncome: string) => {
    const parsedFirstIncome = poundsToInt(firstIncome)
    const parsedSecondIncome = poundsToInt(secondIncome)

    if (parsedFirstIncome > MAX_INCOME || parsedSecondIncome > MAX_INCOME) {
      setAnswer(0)
      return
    }

    const answer = (parsedFirstIncome + parsedSecondIncome) * 5
    setAnswer(answer)
  }

  useEffect(() => {
    calculate(firstIncome.value, secondIncome.value)
  }, [firstIncome.value, secondIncome.value])

  return (
    <>
      <Modal isVisible={isVisible} hideModal={toggleModal}>
        <SP>
          Calculating how much you can borrow depends on a few things, like:
        </SP>
        <BulletList
          list={[
            "How much you spend each month on regular commitments",
            "If you're paid a basic salary or receive a bonus/commission/overtime",
            "If you're self employed",
            "The amount of deposit you have",
            "Your age, and whether you’re looking to borrow beyond your retirement ",
          ]}
        />
        <p>
          Every lender has different criteria that determine how much you can
          borrow. As mortgage brokers, we can help you find the right lender for
          your borrowing needs. We can also look at your individual situation to
          see if it’s possible to borrow more than the calculator says.
        </p>
        <span>
          * All potential borrowing is subject to affordability checks and
          credit status
        </span>
      </Modal>
      <Calculator
        analyticsID="BorrowCalculator"
        title="How much can I borrow?"
        subtitle="See how much you can borrow with our mortgage calculator."
        answerText="You could borrow:"
        answerValue={answer > 0 ? `up to ${intToPounds(answer)}` : undefined}
        orientation={orientation}
        showModal={toggleModal}
      >
        <SCalcInputWrapper>
          <InputPounds
            label="Annual income"
            value={firstIncome.value}
            required={true}
            max={MAX_INCOME}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              firstIncomeDispatch({
                data: event.target.value,
                type: "setValue",
              })
            }
            infoTextVariant="light"
          />
        </SCalcInputWrapper>
        <SCalcInputWrapper>
          <InputPounds
            label="Second annual income"
            value={secondIncome.value}
            max={MAX_INCOME}
            infoText="If applicable"
            infoTextVariant="light"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              secondIncomeDispatch({
                data: event.target.value,
                type: "setValue",
              })
            }
          />
        </SCalcInputWrapper>
      </Calculator>
    </>
  )
}

export default BorrowCalculator
