import React from "react"
import styled, { css } from "styled-components"

import { h6 } from "base_css/mixins"
import { rems } from "base_css/utils/fontSize"
import { breakpoint } from "base_css/vars"
import { Cell, Container, Grid } from "components/atoms/Grid"
import Steps from "components/atoms/Steps"

const SHowItWorks = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.xLarge};
  margin-top: ${({ theme }) => theme.spacing.xLarge};
  text-align: center;
`

const SHeader = styled.h1`
  color: ${({ theme }) => theme.color.accent};
  margin-bottom: ${({ theme }) => theme.spacing.large};
`

const SColorfulLine = css`
  background-image: linear-gradient(
    90deg,
    ${({ theme }) => theme.color.primary} 0%,
    ${({ theme }) => theme.color.accent} 100%
  );
  bottom: -${rems(17)};
  height: ${rems(2)};
  position: relative;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.pavement};
`

const SLine = styled.div`
  ${breakpoint.small} {
    display: none;
  }

  ${breakpoint.mediumUp} {
    ${SColorfulLine}
  }
`

const SMultipleLines = styled.div`
  ${breakpoint.small} {
    ${SColorfulLine}
  }

  ${breakpoint.mediumUp} {
    display: none;
  }
`

const SSubHeader = styled.h2`
  ${h6}/* stylelint-disable-next-line */
`

const SSubText = styled.p`
  color: ${({ theme }) => theme.color.primary};
  font-size: ${({ theme }) => theme.fontSize.small};
  margin-bottom: 0;
  padding-left: ${({ theme }) => theme.spacing.small};
  padding-right: ${({ theme }) => theme.spacing.small};
`

const SStepsWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.mediumLarge};
`

export const HowItWorksSteps = [
  {
    header: "Tell us about yourself",
    subtext:
      "Answer some simple questions about your current situation and let us know how we can help.",
  },
  {
    header: "Let’s chat",
    subtext:
      "We’ll talk you through the process, what’s possible (including what you can borrow) and what your mortgage will cost.",
  },
  {
    header: "Get up and running",
    subtext:
      "Once you find a property we’ll help guide you through the whole process from applying for the mortgage to getting the keys.",
  },
  {
    header: "Stay on track",
    subtext:
      "We’ll work with you to ensure that for the life of your mortgage you never pay more than you should.",
  },
]

interface IHowItWorksStep {
  index: number
  header: string
  subtext: string
}

const HowItWorksStep = ({ index, header, subtext }: IHowItWorksStep) => (
  <Cell width={12} mdWidth={3} mdLeft={0}>
    <SMultipleLines />
    <Steps num={index} />
    <SSubHeader>{header}</SSubHeader>
    <SSubText>{subtext}</SSubText>
  </Cell>
)

const HowItWorks = () => (
  <SHowItWorks>
    <Container>
      <SHeader>How does it work?</SHeader>
      <SLine />
      <SStepsWrapper>
        <Grid>
          {HowItWorksSteps.map((step, index) => (
            <HowItWorksStep
              index={index + 1}
              header={step.header}
              subtext={step.subtext}
              key={index}
            />
          ))}
        </Grid>
      </SStepsWrapper>
    </Container>
  </SHowItWorks>
)

export default HowItWorks
