import React from "react"
import styled from "styled-components"

import { rems } from "base_css/utils/fontSize"

interface IStepInner {
  border?: boolean
}

const SStepInner = styled.div<IStepInner>`
  background-color: ${({ theme }) => theme.color.white};
  border: ${({ border, theme }) =>
    border ? `1px solid ${theme.color.accent}` : "none"};
  border-radius: 50%;
  box-shadow: ${({ border }) =>
    border ? "none" : `0 ${rems(5)} ${rems(10)} 0 rgba(34, 34, 34, 0.15)`};
  color: ${({ theme }) => theme.color.accent};
  height: ${rems(30)};
  margin: 0 auto ${({ theme }) => theme.spacing.medium} auto;

  /* below -> fix to vertically center the number as font baseline is off */
  padding-top: ${rems(6)};
  position: relative;
  width: ${rems(30)};
  z-index: ${({ theme }) => theme.zIndex.curb};
`

interface ISteps {
  num: number
  border?: boolean
}

const Steps = ({ num, border }: ISteps) => (
  <SStepInner border={border}>{num}</SStepInner>
)

export default Steps
