import React, { useContext } from "react"
import styled, { useTheme } from "styled-components"

import { CompanyContext } from "companyContext"
import { Cell, Container, Grid } from "components/atoms/Grid"
import { defaultMetaDescription } from "components/atoms/HeadWrapper"
import MortgageLogo from "components/atoms/MortgageLogo"
import CompanyLogo from "components/molecules/CompanyLogo"
import GetStartedButton from "components/molecules/GetStartedButton"

const SHeading = styled.h1`
  color: ${({ theme }) => theme.color.primary};
`

const SHeroImageCell = styled(Cell)`
  align-items: center;
  display: flex;
  justify-content: center;
`

const Hero = () => {
  const theme = useTheme()
  const { logoUrl, companyName, introducer } = useContext(CompanyContext)

  return (
    <Container
      marginBottom={theme.spacing.large}
      marginTop={theme.spacing.large}
      lgMarginBottom={theme.spacing.xLarge}
      lgMarginTop={theme.spacing.xLarge}
    >
      <Grid columnGap={theme.spacing.xLarge}>
        <Cell width={12} mdWidth={6} lgLeft={2} middle={true}>
          <SHeading>Expert mortgage advice at your fingertips</SHeading>
          <p>
            {companyName
              ? introducer
                ? `We've teamed up with ${companyName} to offer free mortgage advice. Whether you're a first-time buyer or already own a house, we're here to help you every step of the way.`
                : `We've teamed up with ${companyName} to offer free mortgage advice to all their employees. Whether you're a first-time buyer or already own a house, we're here to help you every step of the way.`
              : defaultMetaDescription}
          </p>
          <GetStartedButton analyticsID="Hero GetStartedButton" />
        </Cell>
        {logoUrl && companyName ? (
          <SHeroImageCell width={12} mdWidth={4} mdLeft={8}>
            <CompanyLogo
              logoUrl={logoUrl}
              companyName={companyName}
              testId="hero-company-image"
            />
          </SHeroImageCell>
        ) : (
          <SHeroImageCell width={12} mdWidth={4} mdLeft={8}>
            <MortgageLogo testId="hero-general-image" />
          </SHeroImageCell>
        )}
      </Grid>
    </Container>
  )
}

export default Hero
